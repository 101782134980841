import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Placeholder from "react-bootstrap/Placeholder";
import { shops, renderCustomerAttributes } from "../Utils/utils";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { shopifyCustomer, llCustomer, error, loyalty_tier_membership } from "../interfaces";

export default function LoyaltyPointsMigration() {

    // Source Url variables
    const [sourceShopUrl, setSourceShopUrl] = useState<string | null>(null);
    const [sourceEmail, setSourceEmail] = useState<string | null>(null);
    const [sourceCustomers, setSourceCustomers] = useState<Array<shopifyCustomer> | null>(null);
    
    const [selectedSourceCustomer, setSelectedSourceCustomer] = useState<shopifyCustomer | null>(null);
    const [selectedSourceLLCustomer, setSelectedSourceLLCustomer] = useState<llCustomer | null>(null);
    
    // Target Url variables
    const [targetShopUrl, setTargetShopUrl] = useState<string | null>(null);
    const [targetCustomers, setTargetCustomers] = useState<Array<shopifyCustomer> | null>(null);
    const [createNewCustomerInTargetShop, setCreateNewCustomerInTargetShop] = useState<boolean>(false);
    const [useNewEmailInTargetShop, setUseNewEmailInTargetShop] = useState<boolean | null>(null);
    const [selectedTargetCustomer, setSelectedTargetCustomer] = useState<shopifyCustomer | null>(null);
    const [selectedTargetLLCustomer, setSelectedTargetLLCustomer] = useState<llCustomer | null>(null);
    const [targetCustomerEmail, setTargetCustomerEmail] = useState<string | null>(null);
    const [targetCustomerEmailConfirmation, setTargetCustomerEmailConfirmation] = useState<string | null>(null);
    const [targetCustomerFirstName, setTargetCustomerFirstName] = useState<string | null>(null);
    const [targetCustomerLastName, setTargetCustomerLastName] = useState<string | null>(null);
    const [targetCustomerEmailNotValid, setTargetCustomerEmailNotValid] = useState<boolean>(false);

    // Processing States
    const [processingSearch, setProcessingSearch] = useState<boolean>(false);
    const [processingSearchCustomerInTargetShop, setProcessingSearchCustomerInTargetShop] = useState<boolean>(false);
    const [processingCreateCustomerInTargetShop, setProcessingCreateCustomerInTargetShop] = useState<boolean>(false);
    const [processingMigrateLoyaltyPoints, setProcessingMigrateLoyaltyPoints] = useState<boolean>(false);
    const [processingMigration, setProcessingMigration] = useState<boolean>(false);
    const [fetchingLLCustomerData, setFetchingLLCustomerData] = useState<boolean>(false);

    // Success States
    const [successfullyRemovedPointsFromSource, setSuccessfullyRemovedPointsFromSource] = useState<boolean>(false);
    const [successfullyAddedPointsToTarget, setSuccessfullyAddedPointsToTarget] = useState<boolean>(false);
    const [successfullyMigratedPoints, setSuccessfullyMigratedPoints] = useState<boolean>(false);

    const [errorMessages, setErrorMessages] = useState<Array<error>>([]);

    var AlertSpinner = function AlertSpinner(data: {variant: string, message: string}) {
        return (
            <Alert variant={data.variant} className="py-2">
                <Spinner animation="border" role="status" size="sm" aria-hidden="true" />
                <span className="visually-hidden">Loading...</span>
                <span className="ms-3">{data.message}</span>
            </Alert>
        );
    };

    useEffect(() => {
        // console.log("##### use effect source email.");
        if (targetCustomerEmail != null && targetCustomerEmailConfirmation != null) {
            if (targetCustomerEmail != targetCustomerEmailConfirmation) {
                console.log("##### NOT VALID");
                setTargetCustomerEmailNotValid(true);
            } else {
                setTargetCustomerEmailNotValid(false);
            }
        }
    }, [targetCustomerEmail, targetCustomerEmailConfirmation]);

    useEffect(() => {
        // console.log("##### use effect source Shop.");
        if (selectedSourceCustomer != null && sourceShopUrl != null) {
            setSourceCustomers(null);
            setFetchingLLCustomerData(true);
            getLoyaltyInformationByCustomerId(selectedSourceCustomer, setSelectedSourceLLCustomer, sourceShopUrl);
        }
    }, [selectedSourceCustomer]);

    useEffect(() => {
        console.log("##### use effect target Shop.");
        console.log("##### selectedTargetCustomer", selectedTargetCustomer);

        migrateLoyaltyPointsToTargetShop();
    }, [selectedTargetCustomer]);
    
    useEffect(() => {
        // console.log("##### use effect source LL Customer.");
        if (selectedSourceLLCustomer != null) {
            setFetchingLLCustomerData(false);
        }
    }, [selectedSourceLLCustomer]);

    async function migrateLoyaltyPointsToTargetShop() {
        console.log("migrate loyalty points to target shop");
        setProcessingMigrateLoyaltyPoints(true);

        var removedPoints = false;
        if (successfullyRemovedPointsFromSource) {
            removedPoints = true;
        } else {
            removedPoints = await removeLoyaltyPointsFromSourceShop();
        }

        if (removedPoints) {
            var addedPoints = false;
            if (successfullyAddedPointsToTarget) {
                addedPoints = true;
            } else {
                addedPoints = await addLoyaltyPointsToTargetShop();
            }

            if (addedPoints) {
                setSuccessfullyAddedPointsToTarget(true);
                setProcessingMigration(false);
                setSuccessfullyMigratedPoints(true);
            }
        }
    }

    async function addLoyaltyPointsToTargetShop() {
        console.log("add loyalty points from source shop");
        var success = false;

        console.log("selectedTargetLLCustomer", selectedTargetLLCustomer);
        console.log("selectedTargetCustomer", selectedTargetCustomer);

        if (selectedTargetCustomer != null && targetShopUrl != null && selectedSourceLLCustomer != null) {
            var targetLLCustomer = await getLoyaltyInformationByCustomerId(selectedTargetCustomer, null, targetShopUrl);

            console.log("targetLLCustomer", targetLLCustomer);

            if (targetLLCustomer != null && targetShopUrl != null) {
                // var points = 2;
                var points = selectedSourceLLCustomer.points_approved;
                console.log("points to add", points);
                var reason = `Added ${points} Point(s) for Migration from ${sourceShopUrl} to ${targetShopUrl} at ${new Date().toLocaleString("de-DE", {timeZone: "Europe/Berlin"})}`;
                var merchantId = targetLLCustomer.merchant_id;

                console.log("merchantId", merchantId);

                const setPointsForLLCustomer = httpsCallable(functions, "setPointsForLLCustomer");
                var response: any = await setPointsForLLCustomer({ shop_url: targetShopUrl, points: points, merchantId: merchantId, reason: reason });
                
                console.log(response);
                if (response.data.status == "201" || response.data.status == 201) {
                    setSuccessfullyAddedPointsToTarget(true);
                    success = true;
                } else if (response.data.error != null) {
                    setErrorMessages([...errorMessages, {title: `Fehler beim Hinzufügen der Punkte im Ziel-Shop:`, message: response.data.error}]);
                    setProcessingMigration(false);
                    setProcessingMigrateLoyaltyPoints(false);
                } else {
                    setErrorMessages([...errorMessages, {title: `Fehler beim Hinzufügen der Punkte im Ziel-Shop:`, message: response.data.status}]);
                    setProcessingMigration(false);
                    setProcessingMigrateLoyaltyPoints(false);
                }
            } 
        }
        
        return success;
    }

    async function removeLoyaltyPointsFromSourceShop() {
        console.log("remove loyalty points from source shop");
        var success = false;

        if (selectedSourceLLCustomer != null && targetShopUrl != null) {
            // var points = 2;
            var points = selectedSourceLLCustomer.points_approved;
            console.log("points to remove", points);
            var reason = `Removed ${points} Point(s) for Migration from ${sourceShopUrl} to ${targetShopUrl} at ${new Date().toLocaleString("de-DE", {timeZone: "Europe/Berlin"})}`;
            var merchantId = selectedSourceLLCustomer.merchant_id;

            console.log("merchantId", merchantId);

            const removePointsForLLCustomer = httpsCallable(functions, "removePointsForLLCustomer");
            var response: any = await removePointsForLLCustomer({ shop_url: sourceShopUrl, points: points, merchantId: merchantId, reason: reason });

            console.log(response);

            if (response.data.status == "204" || response.data.status == 204) {
                setSuccessfullyRemovedPointsFromSource(true);
                success = true;
            } else if (response.data.error != null) {
                setErrorMessages([...errorMessages, {title: `Fehler beim Entfernen der Punkte im Quell-Shop:`, message: response.data.error}]);
                setProcessingMigration(false);
                setProcessingMigrateLoyaltyPoints(false);
            } else if (response.data.status == "422") {
                setErrorMessages([...errorMessages, {title: `Fehler beim Entfernen der Punkte im Quell-Shop:`, message: response.data.statusText}]);
                setProcessingMigration(false);
                setProcessingMigrateLoyaltyPoints(false);
            }
            
        }
        
        return success;
    }

    async function createCustomerInShop(shopUrl: string, customer: shopifyCustomer) {
        console.log("create customer in shop", shopUrl, customer);
        setProcessingCreateCustomerInTargetShop(true);

        const createCustomer = httpsCallable(functions, "createCustomer");
        var response: any = await createCustomer({ shop_url: shopUrl, email: customer.email, firstName: customer.firstName, lastName: customer.lastName });

        console.log("createCustomerInShop response", response);

        if (response.data.customerCreate != null && response.data.customerCreate.customer != null) {
            setSelectedTargetCustomer({
                id: response.data.customerCreate.customer.id,
                email: response.data.customerCreate.customer.email,
                displayName: response.data.customerCreate.customer.displayName,
                firstName: response.data.customerCreate.customer.firstName,
                lastName: response.data.customerCreate.customer.lastName
            });
        } else {
            var errors = `Fehler bei der Erstellung des Kunden im Ziel-Shop: ${response.data.customerCreate.userErrors.map((error: any) => `${error.message}. `)}`;
            setErrorMessages(errorMessages => [...errorMessages, {title: "Fehler bei der Erstellung des Kunden im Ziel-Shop:", message: `${response.data.customerCreate.userErrors.map((error: any) => `${error.message}. `)}`}]);
            setProcessingMigration(false);
        }

        setProcessingCreateCustomerInTargetShop(false);
    }

    async function searchCustomerForSourceShop() {
        if (sourceShopUrl != null) {
            var customers: Array<shopifyCustomer> = await searchCustomerForShop(sourceShopUrl);
            if (customers.length == 1) {
                setSelectedSourceCustomer(customers[0]);
            } else {
                setSourceCustomers(customers);
            }
        }
        console.log("Processing search: false");
        setProcessingSearch(false);    
    }

    async function searchCustomerForTargetShop(_targetShopUrl: string) {
        console.log("search customer for target shop", _targetShopUrl);
        if (_targetShopUrl != null) {
            var customers: Array<shopifyCustomer> = await searchCustomerForShop(_targetShopUrl);
            setTargetCustomers(customers);
            if (customers.length == 0) {
                setCreateNewCustomerInTargetShop(true);
            } 
        }

        setProcessingSearchCustomerInTargetShop(false);
    }

    async function searchCustomerForShop(shopUrl: string) {
        const getCustomersByAttributes = httpsCallable(functions, "getCustomersByAttributes");
        var response: any = await getCustomersByAttributes({ shop_url: shopUrl, additionalInformation: `email:${sourceEmail}` });

        var customers: Array<shopifyCustomer> = [];
        if (response.data.querySuccesfull) {
            console.log("response query successful");
            response.data.customers.map((customer: any) => {
                customers.push({
                    id: customer.id,
                    email: customer.email,
                    displayName: customer.displayName,
                    firstName: customer.firstName,
                    lastName: customer.lastName
                });
            });
        }

        console.log(customers);

        return customers;
    }

    async function getLoyaltyInformationByCustomerId(customer: shopifyCustomer, setCustomer: Function | null, shop_url: string) {
        if (customer != null) {
            const getLLCustomer = httpsCallable(functions, "getLLCustomer");

            var response: any = null;
            response = await getLLCustomer({ shop_url: shop_url, email: customer.email, limit: 500 });

            var obj: llCustomer | null = null;

            console.log("getLLCustomer response", response);

            if (response.data.customers.length == 1) {
                var loyalty_tier_membership: loyalty_tier_membership | null = null;
                var loyalty_tier_membership_tier_name: string = "No Tier";
                if (response.data.customers[0].loyalty_tier_membership != null) {
                    loyalty_tier_membership = {
                        expires_at: response.data.customers[0].loyalty_tier_membership.expires_at,
                        started_at: response.data.customers[0].loyalty_tier_membership.started_at,
                        manual: response.data.customers[0].loyalty_tier_membership.manual,
                        loyalty_tier: {
                            id: response.data.customers[0].loyalty_tier_membership.loyalty_tier.id,
                            name: response.data.customers[0].loyalty_tier_membership.loyalty_tier.name,
                            default: response.data.customers[0].loyalty_tier_membership.loyalty_tier.default
                        }
                    }
                    loyalty_tier_membership_tier_name = response.data.customers[0].loyalty_tier_membership.loyalty_tier.name;
                }
                obj = {
                    id: response.data.customers[0].id,
                    merchant_id: response.data.customers[0].merchant_id,
                    email: response.data.customers[0].email,
                    points_approved: response.data.customers[0].points_approved,
                    loyalty_tier_membership: loyalty_tier_membership,
                    loyalty_tier_membership_tier_name: loyalty_tier_membership_tier_name
                };
            } else {
                var response_secondAttempt: any = null;
                response_secondAttempt = await getLLCustomer({ shop_url: shop_url, email: customer.email, limit: 500 });

                if (response_secondAttempt.data.customers.length == 1) {
                    var loyalty_tier_membership: loyalty_tier_membership | null = null;
                    var loyalty_tier_membership_tier_name: string = "No Tier";
                    if (response_secondAttempt.data.customers[0].loyalty_tier_membership != null) {
                        loyalty_tier_membership = {
                            expires_at: response_secondAttempt.data.customers[0].loyalty_tier_membership.expires_at,
                            started_at: response_secondAttempt.data.customers[0].loyalty_tier_membership.started_at,
                            manual: response_secondAttempt.data.customers[0].loyalty_tier_membership.manual,
                            loyalty_tier: {
                                id: response_secondAttempt.data.customers[0].loyalty_tier_membership.loyalty_tier.id,
                                name: response_secondAttempt.data.customers[0].loyalty_tier_membership.loyalty_tier.name,
                                default: response_secondAttempt.data.customers[0].loyalty_tier_membership.loyalty_tier.default
                            }
                        }
                        loyalty_tier_membership_tier_name = response_secondAttempt.data.customers[0].loyalty_tier_membership.loyalty_tier.name;
                    }
                    obj = {
                        id: response_secondAttempt.data.customers[0].id,
                        merchant_id: response_secondAttempt.data.customers[0].merchant_id,
                        email: response_secondAttempt.data.customers[0].email,
                        points_approved: response_secondAttempt.data.customers[0].points_approved,
                        loyalty_tier_membership: loyalty_tier_membership,
                        loyalty_tier_membership_tier_name: loyalty_tier_membership_tier_name
                    };
                } else {
                    setErrorMessages(errorMessages => [...errorMessages, {
                        title: "Fehler bei der Abfrage der Loyalty Lion Kundeninformation:", 
                        message: `Es wurde kein Kunde mit der E-Mail ${customer.email} im Shop ${shop_url} gefunden.`
                    }]);
                }
            }

            if (setCustomer != null && obj != null) {
                setCustomer(obj);
            } else if (obj) {
                return obj;
            }
        }

        return null;
    }

    // Helper Functions

    function resetTargetShopCustomer() {
        setSelectedTargetCustomer(null);
        setTargetCustomerEmail(null);
        setTargetCustomerEmailConfirmation(null);
        setTargetCustomerFirstName(null);
        setTargetCustomerLastName(null);
        setTargetCustomerEmailNotValid(false);
        setTargetCustomers(null);
    }

    function resetDataAfterTargetShopSelected() {
        // Reset sucess states
        setSuccessfullyMigratedPoints(false);
        setSuccessfullyRemovedPointsFromSource(false);
        setSuccessfullyAddedPointsToTarget(false);

        // Reset target url variables
        // Excep target shop url
        setTargetCustomers(null);
        setCreateNewCustomerInTargetShop(false);
        setUseNewEmailInTargetShop(false);
        setSelectedTargetCustomer(null);
        setTargetCustomerEmail(null);
        setTargetCustomerEmailConfirmation(null);
        setTargetCustomerFirstName(null);
        setTargetCustomerLastName(null);
        setTargetCustomerEmailNotValid(false);

        // Reset processing states
        // Except processing search (customer in source shop)
        // Except processing search customer in target shop
        setProcessingCreateCustomerInTargetShop(false);
        setProcessingMigrateLoyaltyPoints(false);
        setFetchingLLCustomerData(false);
        setProcessingMigration(false);

        setErrorMessages([]);
    }

    function resetDataAfterSearchCustomer() {
        // Reset sucess states
        setSuccessfullyMigratedPoints(false);
        setSuccessfullyRemovedPointsFromSource(false);
        setSuccessfullyAddedPointsToTarget(false);

        // Reset target url variables
        setTargetShopUrl(null);
        setTargetCustomers(null);
        setCreateNewCustomerInTargetShop(false);
        setUseNewEmailInTargetShop(false);
        setSelectedTargetCustomer(null);
        setTargetCustomerEmail(null);
        setTargetCustomerEmailConfirmation(null);
        setTargetCustomerFirstName(null);
        setTargetCustomerLastName(null);
        setTargetCustomerEmailNotValid(false);

        // Reset source url variables
        // Except source email and source shop url
        setSourceCustomers(null);
        setSelectedSourceCustomer(null);
        setSelectedSourceLLCustomer(null);

        // Reset processing states
        // Except processing search (customer in source shop)
        setProcessingSearchCustomerInTargetShop(false);
        setProcessingCreateCustomerInTargetShop(false);
        setProcessingMigrateLoyaltyPoints(false);
        setFetchingLLCustomerData(false);
        setProcessingMigration(false);

        setErrorMessages([]);
    }

    function resetMigration() {
        
        setProcessingSearch(false);
        setProcessingSearchCustomerInTargetShop(false);
        setProcessingCreateCustomerInTargetShop(false);
        setProcessingMigrateLoyaltyPoints(false);
        setFetchingLLCustomerData(false);
        setProcessingMigration(false);

        // Reset sucess states
        setSuccessfullyMigratedPoints(false);
        setSuccessfullyRemovedPointsFromSource(false);
        setSuccessfullyAddedPointsToTarget(false);

        // Reset target url variables
        setTargetShopUrl(null);
        setTargetCustomers(null);
        setCreateNewCustomerInTargetShop(false);
        setUseNewEmailInTargetShop(false);
        setSelectedTargetCustomer(null);
        setTargetCustomerEmail(null);
        setTargetCustomerEmailConfirmation(null);
        setTargetCustomerFirstName(null);
        setTargetCustomerLastName(null);
        setTargetCustomerEmailNotValid(false);

        // Reset source url variables
        setSourceShopUrl(null);
        setSourceCustomers(null);
        setSourceEmail(null);
        setSelectedSourceCustomer(null);
        setSelectedSourceLLCustomer(null);

        setErrorMessages([]);
    }

    // User Interaction Functions

    function searchCustomer() {
        if (sourceShopUrl != null && sourceShopUrl != "") {
            if (sourceEmail != null && sourceEmail != "") {
                resetDataAfterSearchCustomer();
                setProcessingSearch(true);
                searchCustomerForSourceShop();
            }
        }
    }

    function setCustomer(index: number) {
        if (sourceCustomers != null) {
            setSelectedSourceCustomer(sourceCustomers[index]);
        }
    }

    function handleKeyPress_searchCustomer(target: any) {
        if (target.charCode == 13) {
            searchCustomer();
        }
    }

    function validateEmailCreateCustomer(email: string) {
        if (targetCustomerEmailConfirmation == null) {
            console.log("VALIDATE EMAIL");
            if (email != targetCustomerEmail) {
                setTargetCustomerEmailNotValid(true);
            }
            setTargetCustomerEmailConfirmation(email);
        }
    }

    function onChangeEmailCreateCustomer(email: string) {
        if (targetCustomerEmailConfirmation != null) {
            setTargetCustomerEmailConfirmation(email);
        }
    }
    
    function handleKeyPress_emailCreateCustomer(target: any) {
        console.log("HANDLE KEY PRESS");
        if (target.value == targetCustomerEmail) {
            setTargetCustomerEmailNotValid(false);
        } else {
            setTargetCustomerEmailNotValid(true);
        }
    }

    function setTargetShop(targetShopUrl: string) {
        console.log("$$$$ set target shop", targetShopUrl);
        resetDataAfterTargetShopSelected();

        setTargetShopUrl(targetShopUrl);

        resetTargetShopCustomer();
        setUseNewEmailInTargetShop(null);
        setCreateNewCustomerInTargetShop(false);

        setProcessingSearchCustomerInTargetShop(true);
        searchCustomerForTargetShop(targetShopUrl);
    }

    function setSourceEmailForTargetShop() {
        console.log("USE SOURCE EMAIL FOR TARGET SHOP");

        if (targetShopUrl != null && selectedSourceCustomer != null) {
            setProcessingMigration(true);

            createCustomerInShop(targetShopUrl, {
                email: selectedSourceCustomer.email,
                firstName: selectedSourceCustomer.firstName,
                lastName: selectedSourceCustomer.lastName,
                displayName: selectedSourceCustomer.displayName,
                id: selectedSourceCustomer.id
            });
        }
    }

    function setNewEmailForTargetShop() {
        console.log("USE NEW EMAIL FOR TARGET SHOP");

        if (targetShopUrl != null && targetCustomerEmail != null && targetCustomerEmailConfirmation != null && targetCustomerEmail == targetCustomerEmailConfirmation && targetCustomerFirstName != null && targetCustomerLastName != null) {
            setProcessingMigration(true);

            createCustomerInShop(targetShopUrl, {
                email: targetCustomerEmail,
                firstName: targetCustomerFirstName,
                lastName: targetCustomerLastName,
                id: "",
                displayName: ""
            });
        }
    }

    function setSourceCustomerAsTargetCustomer() {
        console.log("USE SOURCE CUSTOMER AS TARGET CUSTOMER");

        console.log("targetCustomers", targetCustomers);

        if (targetCustomers != null) {
            setProcessingMigration(true);
            setSelectedTargetCustomer(targetCustomers[0]);      
        }
    }

    function selectedToCreateNewCustomer() {
        setCreateNewCustomerInTargetShop(true);
        setUseNewEmailInTargetShop(true);
    }

    return (
        <Container className="px-3 py-4 formularWrapper">
            <Container className="p-0">
                <Row key="row_1">
                    <Col key="col_1" className="mb-2">
                        <h1 className="h2">Loyalty Points Migration</h1>
                    </Col>
                    <Col key="col_2" className="text-end mb-2" sm={4}>
                        <Button variant="outline-danger" onClick={() => resetMigration()}>Neue Migration</Button>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-2 p-0">
                <Card>
                    <Card.Header>
                        <b>Quelle auswählen</b>
                    </Card.Header>
                    <Card.Body>
                        <Row key="row_1">
                            <Col key="col_1">
                                <Form.Group className="mb-3" controlId="sourceShop">
                                    <Form.Label>Shop</Form.Label>
                                    <Form.Select 
                                        aria-label="Default select example" 
                                        id="targetShopSelect"
                                        disabled={processingMigration || successfullyMigratedPoints}
                                        onChange={(e) => setSourceShopUrl(e.target.value)}
                                        defaultValue="default"
                                        value={sourceShopUrl != null ? sourceShopUrl : "default"}
                                    >
                                        <option key={`option_0`} disabled={true} value="default">Shop auswählen</option>
                                        {shops.map((item, i) => {
                                            return <option key={`option_${i + 1}`} value={item.url}>{item.label}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        { sourceShopUrl != null &&
                            <>
                                <Row key="row_2">
                                    <Col>
                                        <Form.Label>E-Mail Adresse Kunde</Form.Label>
                                        <Form.Control 
                                            type="email" 
                                            placeholder="z.B. customer@example.de"
                                            disabled={processingMigration || successfullyMigratedPoints}
                                            onKeyPress={handleKeyPress_searchCustomer}
                                            onChange={(e) => setSourceEmail(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                { sourceEmail != null &&
                                    <Row className="mt-3" key="row_3">
                                        <Col>
                                            <Button 
                                                variant="primary" 
                                                onClick={e => searchCustomer()}
                                                disabled={processingSearch || processingSearchCustomerInTargetShop || processingMigration || successfullyMigratedPoints}
                                            >
                                                {processingSearch == true ? 
                                                <>
                                                    <Spinner animation="border" role="status" size="sm" aria-hidden="true"/>
                                                    <span className="visually-hidden">Loading...</span>
                                                </>
                                                : 
                                                <>
                                                    Kunde suchen
                                                </>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                            </>
                        }
                    </Card.Body>
                </Card>
            </Container>
            { processingSearch &&
                <Container className="mt-3 p-0">
                    <Alert variant="info">
                        Kundenkonto wird gesucht...
                    </Alert>
                </Container>
            }
            { sourceCustomers != null && selectedSourceLLCustomer == null && 
                <Container className="mt-3 p-0">
                    { sourceCustomers.length > 0 ?
                        <>
                            <Alert variant="info">
                                Es wurden mehrere Kundenkonten gefunden. Bitte wählen Sie das passende Konto aus.
                            </Alert>
                            { sourceCustomers.map((customer: shopifyCustomer, index: number) => {
                                return (
                                    <Card className="customer-card mb-3">
                                        <Card.Body>
                                            <>
                                                { renderCustomerAttributes([{label: "Name", value: customer.displayName }, {label: "E-Mail", value: customer.email }]) }
                                                <Row className="mt-3" key="row_3">
                                                    <Col>
                                                        <Button 
                                                            variant="outline-primary" 
                                                            onClick={e => setCustomer(index)}
                                                            disabled={fetchingLLCustomerData}
                                                        >
                                                            {fetchingLLCustomerData == true ? 
                                                            <>
                                                                <Spinner animation="border" role="status" size="sm" aria-hidden="true"/>
                                                                <span className="visually-hidden">Loading...</span>
                                                            </>
                                                            : 
                                                            <>
                                                                Kunde wählen
                                                            </>
                                                            }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        </Card.Body>
                                    </Card>
                                )
                            })}
                        </>
                    :
                        <Alert variant="warning">
                            Es konnte kein passendes Kundenkonto gefunden werden.
                        </Alert>
                    }
                </Container>

            }
            { selectedSourceCustomer != null &&
                <>
                    <Container className="mt-3 p-0">
                        <Card className="customer-card">
                            <Card.Header>
                                <b>Ausgewählter Kunde</b>
                            </Card.Header>
                            <Card.Body>
                                { selectedSourceLLCustomer == null ?
                                    renderCustomerAttributes(
                                        [
                                            {label: "Name", value: selectedSourceCustomer.displayName }, 
                                            {label: "E-Mail", value: selectedSourceCustomer.email },
                                            {label: "Approved Points", value: "null" },
                                            {label: "Tier Membership", value: "null" }
                                        ]
                                        )
                                        :
                                        renderCustomerAttributes(
                                            [
                                            {label: "Name", value: selectedSourceCustomer.displayName }, 
                                            {label: "E-Mail", value: selectedSourceCustomer.email },
                                            {label: "Approved Points", value: `${selectedSourceLLCustomer.points_approved}` },
                                            {label: "Tier Membership", value: selectedSourceLLCustomer.loyalty_tier_membership_tier_name }
                                        ]
                                    )
                                }
                            </Card.Body>
                        </Card>
                    </Container>
                    { selectedSourceLLCustomer != null &&
                        <Container className="mt-3 p-0">
                            <Card>
                                <Card.Header>
                                    <b>Ziel Shop auswählen</b>
                                </Card.Header>
                                <Card.Body>
                                    <Row key="row_1">
                                        <Col key="col_1">
                                            <Form.Group controlId="sourceShop">
                                                <Form.Label>Shop</Form.Label>
                                                <Form.Select 
                                                    aria-label="Default select example" 
                                                    id="targetShopSelect"
                                                    disabled={processingMigration || successfullyMigratedPoints}
                                                    onChange={(e) => setTargetShop(e.target.value)}
                                                    defaultValue="default"
                                                    value={targetShopUrl != null ? targetShopUrl : "default"}
                                                >
                                                    <option key={"option_0"} disabled={true} value="default">Shop auswählen</option>
                                                    {shops.map((item, i) => {
                                                        return <option key={`option_${i + 1}`} disabled={sourceShopUrl == item.url} value={item.url}>{item.label}</option>
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    { processingSearchCustomerInTargetShop && 
                                        <Row key="row_2" className="mt-3">
                                            <Col>
                                                <AlertSpinner variant="info" message="Ausgewählter Kunde wird im Ziel Shop gesucht ..." />
                                            </Col>
                                        </Row>
                                    }
                                    { 
                                    targetCustomers != null && 
                                    targetCustomers.length > 0 && 
                                    !processingSearchCustomerInTargetShop && 
                                    !createNewCustomerInTargetShop && 
                                        <>
                                            <Row key="row_3" className="mt-3">
                                                <Col>
                                                    <Alert variant="info">
                                                        Für den gewählten Ziel-Shop wurde ein Kundenkonto mit der gleichen E-Mail-Adresse gefunden.
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            <Row key="row_4">
                                                <Col>
                                                    <Button 
                                                        variant="primary" 
                                                        onClick={e => setSourceCustomerAsTargetCustomer()}
                                                        disabled={processingSearch || processingMigration || successfullyMigratedPoints }
                                                    >
                                                        {processingSearch == true || processingMigration == true ? 
                                                        <>
                                                            <Spinner animation="border" role="status" size="sm" aria-hidden="true"/>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </>
                                                        : 
                                                        <>
                                                            Konto verwenden & Migration starten
                                                        </>
                                                        }
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button 
                                                        variant="outline-primary" 
                                                        onClick={e => selectedToCreateNewCustomer()}
                                                        disabled={processingSearch || processingMigration || successfullyMigratedPoints }
                                                    >
                                                        {processingSearch == true ? 
                                                        <>
                                                            <Spinner animation="border" role="status" size="sm" aria-hidden="true"/>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </>
                                                        : 
                                                        <>
                                                            Neues Konto erstellen
                                                        </>
                                                        }
                                                    </Button>   
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Card.Body>
                            </Card>
                        </Container>
                    }
                    { createNewCustomerInTargetShop &&
                        <Container className="mt-3 p-0">
                            <Card>
                                <Card.Header>
                                    <b>Kundenkonto im Ziel Shop erstellen</b>
                                </Card.Header>
                                <Card.Body>
                                    { useNewEmailInTargetShop == null &&
                                        <Row key="row_1">
                                            <Col key="col_1">
                                                <Button 
                                                        variant="primary" 
                                                        onClick={e => setSourceEmailForTargetShop()}
                                                        disabled={processingSearch || processingSearchCustomerInTargetShop || processingMigration || successfullyMigratedPoints }
                                                >
                                                    { processingSearch || processingMigration ? 
                                                    <>
                                                        <Spinner animation="border" role="status" size="sm" aria-hidden="true"/>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </>
                                                    : 
                                                    <>
                                                        <div>Name & E-Mail übernehmen</div>
                                                        <div>Kunde erstellen & Loyalty Punkte migrieren</div>
                                                    </>
                                                    }
                                                </Button>   
                                            </Col>
                                            <Col key="col_2">
                                                <Button 
                                                        variant="outline-primary" 
                                                        onClick={e => setUseNewEmailInTargetShop(true)}
                                                        disabled={processingSearch || processingSearchCustomerInTargetShop || processingMigration || successfullyMigratedPoints }
                                                >
                                                    {processingSearch == true ? 
                                                    <>
                                                        <Spinner animation="border" role="status" size="sm" aria-hidden="true"/>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </>
                                                    : 
                                                    <>
                                                        Neue E-Mail Adresse
                                                    </>
                                                    }
                                                </Button>   
                                            </Col>
                                        </Row>
                                    }
                                    { useNewEmailInTargetShop != null && useNewEmailInTargetShop == true &&
                                        <>
                                            <Row key="row_2">
                                                <Col key="col_1">
                                                    <Form.Label>E-Mail Adresse Kunde</Form.Label>
                                                    <Form.Control 
                                                        type="email"
                                                        disabled={processingMigration || successfullyMigratedPoints}
                                                        placeholder="z.B. customer@example.de"
                                                        isInvalid={targetCustomerEmailNotValid}
                                                        onChange={e => setTargetCustomerEmail(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row key="row_3" className="mt-3">
                                                <Col key="col_1">
                                                    <Form.Label>E-Mail Adresse Kunde (Bestätigung)</Form.Label>
                                                    <Form.Control 
                                                        type="email"
                                                        disabled={processingMigration || successfullyMigratedPoints}
                                                        isInvalid={targetCustomerEmailNotValid}
                                                        onChange={e => onChangeEmailCreateCustomer(e.target.value)}
                                                        onBlur={e => validateEmailCreateCustomer(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            { targetCustomerEmailNotValid && 
                                                <Row key="row_4" className="mt-3">
                                                    <Col key="col_1">
                                                        <Alert variant="danger">
                                                            Die E-Mail-Adressen stimmen nicht überein.
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row key="row_5" className="mt-3">
                                                <Col key="col_1">
                                                    <Form.Label>Vorname</Form.Label>
                                                    <Form.Control 
                                                        type="email" 
                                                        placeholder="z.B. Max"
                                                        disabled={processingMigration || successfullyMigratedPoints}
                                                        onChange={e => setTargetCustomerFirstName(e.target.value)}
                                                    />
                                                </Col>
                                                <Col key="col_2">
                                                    <Form.Label>Nachname</Form.Label>
                                                    <Form.Control 
                                                        type="email" 
                                                        placeholder="z.B. Mustermann"
                                                        disabled={processingMigration || successfullyMigratedPoints}
                                                        onChange={e => setTargetCustomerLastName(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row key="row_6" className="mt-3">
                                                <Col key="col_1">
                                                    <Button 
                                                            variant="primary" 
                                                            onClick={e => setNewEmailForTargetShop()}
                                                            disabled={processingSearch || targetCustomerEmailNotValid || processingMigration || successfullyMigratedPoints}
                                                    >
                                                        {processingSearch == true || processingMigration ? 
                                                        <>
                                                            <Spinner animation="border" role="status" size="sm" aria-hidden="true"/>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </>
                                                        : 
                                                        <>
                                                            Kunde erstellen & Loyalty Punkte migrieren
                                                        </>
                                                        }
                                                    </Button> 
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Card.Body>
                            </Card>
                        </Container>
                    }
                    { processingMigration && processingCreateCustomerInTargetShop &&
                        <Container className="mt-3 p-0">
                            <AlertSpinner variant="info" message="Neuer Kunde wird im Ziel-Shop erstellt ..." />
                        </Container>
                    }
                    { !processingCreateCustomerInTargetShop && selectedTargetCustomer != null && !successfullyMigratedPoints &&
                        <Container className="mt-3 p-0">
                            <Alert variant="success" className="py-2">
                                Kunde erfolgreich im Ziel-Shop erstellt / ausgewählt.
                            </Alert>
                        </Container>
                    }
                    { successfullyRemovedPointsFromSource && !successfullyMigratedPoints &&
                        <Container className="mt-3 p-0">
                            <Alert variant="success" className="py-2">
                                Loyalty Points erfolgreich aus Quell-Shop entfernt.
                            </Alert>
                        </Container>
                    }
                    { successfullyAddedPointsToTarget && !successfullyMigratedPoints &&
                        <Container className="mt-3 p-0">
                            <Alert variant="success" className="py-2">
                                Loyalty Points erfolgreich in den Ziel-Shop übertragen.
                            </Alert>
                        </Container>
                    }
                    { processingMigration && processingMigrateLoyaltyPoints &&
                        <Container className="mt-3 p-0" >
                            <AlertSpinner variant="info" message="Loyalty Points werden in den Ziel-Shop übertragen" />
                        </Container>
                    }
                    { successfullyMigratedPoints &&
                        <>
                            <Container className="mt-3 p-0">
                                <Alert variant="success" className="py-2">
                                    Migration erfolgreich abgeschlossen.
                                </Alert>
                            </Container>
                            <Container className="mt-3 p-0">
                                <Button variant="outline-primary" onClick={e => resetMigration()}>
                                    Neue Migration
                                </Button>
                            </Container>
                        </>
                    }
                </>
            }
            { errorMessages.length > 0 &&
                <Container className="mt-3 p-0">
                    <Alert variant="danger" className="py-2">
                        <Alert.Heading>Fehler</Alert.Heading>
                        {errorMessages.map((error, i) => (
                            <p key={`p_${i}`}>{error.title}<br/>{error.message}</p>
                        ))}
                    </Alert>
                </Container>
            }
        </Container>

    );

}

