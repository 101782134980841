import React, { useRef } from "react";
import { useAuth } from "../Provider/AuthProvider";
import { Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router";
import Card from "react-bootstrap/Card";

const Login = () => {

  const { login } = useAuth();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  async function handleSubmit(event: any) {
    event.preventDefault();

    try {
      await login(emailRef.current?.value, passwordRef.current?.value);
      console.log("Login");
      navigate("/");
    } catch {
      console.log("Error");
    }

  }

  return (
    <Container className="px-3 py-4 formularWrapper">
      <h3>Anmelden</h3>
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control type="email" placeholder="Enter email" ref={emailRef} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Passwort</Form.Label>
              <Form.Control type="password" placeholder="Password" ref={passwordRef} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Melde mich wieder ab." />
            </Form.Group>
            <Button variant="primary" type="submit">
              Anmelden
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;