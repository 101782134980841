import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useAuth } from "../Provider/AuthProvider";
import { collection, getDocs, query, orderBy, limit, where } from "firebase/firestore";
import { auth, database } from "../firebase";
import { giftCardMigrationHistroy } from "../interfaces";
import { renderGiftCardAttributes, getTimeStringFromUnix } from "../Utils/utils";
import { render } from "react-dom";
import Alert from "react-bootstrap/Alert";

export default function GiftCardMigrationHistory({children}: any) {
    const auth = useAuth();

    const [historyDocuments, setHistoryDocuments] = useState<Array<giftCardMigrationHistroy>>([]);
    const [showDetails_cardID, setShowDetails_cardID] = useState<string>("");

    const migrationHistoriesRef = collection(database, "migrationHistory");
    const q = query(migrationHistoriesRef, where("user", "==", auth.currentUser.email), orderBy("migrationTimestamp", "desc"), limit(10));

    useEffect(() => {
        getHistory();
    }, []);

    async function getHistory() {
        var documents: Array<any> = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            documents.push(doc.data());
        });
        setHistoryDocuments(documents);
    }

    console.log(historyDocuments);

    return (
        <Container className="px-3 py-4 formularWrapper">
            <h3>Gift Card Migration - Historie</h3>
            <p>Zeigt die letzen 10 Migration für den Nutzer { auth.currentUser.email } an.</p>
            <Container className="p-0">
            {historyDocuments.length > 0 ? 
                historyDocuments.map(doc => {
                    return (
                        <Card className="mt-3" id={doc.newGiftCard.id}>
                            <Card.Header><b>{ getTimeStringFromUnix(+doc.migrationTimestamp) }</b></Card.Header>
                            { showDetails_cardID == doc.newGiftCard.id ? 
                                <Card.Body>
                                    <h4>Alter Gutschein</h4>
                                    <Card className="mt-2">
                                        <Card.Body>
                                            { doc.oldGiftCardLastCharacters ? 
                                            renderGiftCardAttributes(doc.oldGiftCard, false, false, -1, "", doc.oldGiftCardLastCharacters) :
                                            renderGiftCardAttributes(doc.oldGiftCard, false, false, -1) }
                                        </Card.Body>
                                    </Card>
                                    <h4 className="mt-3">Neuer Gutschein</h4>
                                    <Card className="mt-2 bg-light">
                                        <Card.Body>
                                            { renderGiftCardAttributes(doc.newGiftCard, false, false, -1, doc.newGiftCardCode) }
                                        </Card.Body>
                                    </Card>
                                </Card.Body>
                            :
                                <Card.Body>
                                    <Button variant="outline-primary" onClick={() => setShowDetails_cardID(doc.newGiftCard.id)}>Details anzeigen</Button>
                                </Card.Body>
                            }
                        </Card>
                    ) 
                })
                :
                <Alert variant="info">Keine Historie für den aktuellen Nutzer vorhanden.</Alert>
            }
            </Container>
        </Container>
    )
};