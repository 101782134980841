import React from "react";
import { useAuth } from "../Provider/AuthProvider";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../PandT_Logo_sw_70x.webp';
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router";
import { Navigate } from "react-router-dom";

export default function PT_NavBar({children}: any) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  function handleLogout() {
    logout();
    navigate("/login");
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container className="px-3">
            <Navbar.Brand href="#home">
                <img src={logo} className="logoHeader"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="py-2" id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/">Gift Card Migration</Nav.Link>
                    <Nav.Link href="/giftCardMigration/history">Gift Card Migration - Historie</Nav.Link>
                    <Nav.Link href="/loyaltyPointsMigration">Loyalty Points Migration</Nav.Link>
                </Nav>
                <Nav>
                    <Button className="mt-2" variant="outline-secondary" size="sm" onClick={handleLogout}>
                      Ausloggen
                    </Button>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  )
};