import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth } from "../Provider/AuthProvider";
import Alert from "react-bootstrap/Alert";
import { renderGiftCardAttributes, shops, shopLabels, getCurrencyCodeForShop } from "../Utils/utils";
import { shopItem, convertedShopifyMoney, formFeedback, customerSearchResults, shopifyCustomer, giftCard_information, giftCardSearchResults, giftCard_idlc, giftCard_idlcsu, giftCardMigrationHistroy, shopifyMoney } from "../interfaces";
import { createNoSubstitutionTemplateLiteral } from "typescript";
import { query } from "firebase/firestore";
import { responsePathAsArray } from "graphql";

export default function GiftCardMigration() {

    const auth = useAuth();

    const [searchedLastCharacters, setSearchedLastCharacters] = useState<string>("");
    const [searchGiftCardCustomerMail, setSearchedGiftCardCustomerMail] = useState<string>("");
    const [newGiftCardCustomerSearchResults, setNewGiftCardCustomerSearchResults] = useState<customerSearchResults>({amountResults: -1, results: []});
    const [newGiftCardCustomer, setNewGiftCardCustomer] = useState<{unset: boolean, customer: shopifyCustomer | null}>({unset: true, customer: null});
    const [newGiftCardTargetShopUrl, setNewGiftCardTargetShopUrl] = useState<string>("");
    const [newGiftCardNote, setNewGiftCardNote] = useState<string>(""); 
    const [newGiftCardExpiresOn, setNewGiftCardExpiresOn] = useState<string | null>(null); 
    const [newGiftCard, setNewGiftCard] = useState<{giftCard: giftCard_information, code: string} | null>(null); 

    const [lastCharactersFeedback, setLastCharactersFeedback] = useState<formFeedback>({valid: -1, message: ""});
    const [giftCardSearchResults, setGiftCardSearchResults] = useState<giftCardSearchResults>({amountResults: -1, results: []});
    const [processingSearch, setProcessingSearch] = useState<boolean>(false);
    const [processingMigration, setProcessingMigration] = useState<boolean>(false);
    const [selectedGiftCardResult, setSelectedGiftCardResult] = useState<giftCard_information | null>(null);
    const [currencyConversion, setCurrencyConversion] = useState<convertedShopifyMoney | null>(null);
    const [processingCurrencyConversion, setProcessingCurrencyConversion] = useState<boolean>(false);

    const [migrationError, setMigrationError] = useState<string>("");

    // useEffects

    useEffect(() => {
        if (processingSearch) {
            if (giftCardSearchResults.amountResults > -1) {
                setProcessingSearch(false);
            }
        }
    }, [giftCardSearchResults]);
    
    useEffect(() => {
        if (processingMigration) {
            if (newGiftCard != null) {
                setProcessingMigration(false);
            }
        }
    }, [newGiftCard]);

    // async functions

    async function searchGiftCardInAllShops(lastCharacters: string, additionalInformation: any) {
        var shopUrls = [
            'paperandtea-at',
            'paperandtea-ch',
            'paperandtea',
            'paperandtea-en',
            'paperandtea-dk',
            'paperandtea-no',
            // 'paperandtea-de-dev',
            // 'paperandtea-at-dev'
        ];
        var giftCards: Array<giftCard_idlcsu> = [];
        var results: Array<giftCard_information> = [];

        for(let i = 0; i < shopUrls.length; i++) {
            // var response = await getCardId(shopUrls[i], lastCharacters, additionalInformation);
            var response = await getCardIdByCharacters(shopUrls[i], lastCharacters);
            var giftCardList: Array<giftCard_idlcsu> = []
            response.forEach(item => {
                giftCardList.push({
                    id: item.id,
                    lastCharacters: item.lastCharacters,
                    shopUrl: shopUrls[i]
                });
            });
            giftCards.push(...giftCardList);
        }

        for (let i = 0; i < giftCards.length; i++) {
            var response2 = await getCardInformation(giftCards[i].shopUrl, giftCards[i].id);
            var giftCardInformation: giftCard_information = {
                balance: response2.balance,
                customer: response2.customer,
                enabled: response2.enabled,
                expiresOn: response2.expiresOn,
                initialValue: response2.initialValue,
                note: response2.note,
                shopUrl: giftCards[i].shopUrl,
                id: giftCards[i].id
            }
            if (giftCardInformation.enabled) {
                results.push(giftCardInformation);
            }
        }

        setGiftCardSearchResults({amountResults: results.length, results: results});
    }

    function delay(time: number) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    async function getCardIdByCharacters(shop_url: string, lastCharacters: string) {
        const findGiftCardByCharacters = httpsCallable(functions, "findGiftCardByCharacters");

        var response: any;
        var idList: Array<number> = [];
        var cardIds: Array<giftCard_idlc> = [];
        var page_info: string|null = "";
        
        try {
            var runLoop = true;
            while (runLoop) {
                var limit = 250
                var query = `query=last_characters%3A${lastCharacters}&limit=${limit}`;
                response = await findGiftCardByCharacters({ shop_url: shop_url, query: query, pageInfo: page_info, limit: `${limit}` });

                if (response.data.gift_cards.length > 0) {
                    var counter = 1;
                    response.data.gift_cards.forEach((card: any) => {
                        counter = counter + 1;
                        var idlc = {
                            id: `gid://shopify/GiftCard/${card.id}`,
                            lastCharacters: card.last_characters
                        };
                        if (!idList.includes(card.id)) {
                            if (card.disabled_at == null) {
                                idList.push(card.id);
                                cardIds.push(idlc);
                            }
                        }
                    });
                    runLoop = false;
                    if (response.data.link.includes('rel="next"')) {
                        var linkArray = response.data.link.split(",");

                        var queryString = linkArray[0].split(";")[0];
                        if (linkArray.length > 1) {
                            linkArray.forEach((el: any) => {
                                if (el.includes('rel="next"')) {
                                    queryString = el.split(";")[0];
                                }
                            });
                        }

                        queryString = queryString.slice(1, queryString.length -1);
                        const urlParams = new URLSearchParams(queryString);

                        if (urlParams.get("page_info") != null) {
                            runLoop = true;
                            page_info = urlParams.get("page_info");
                        }
                    }
                } else if (response.data.gift_cards.length == 0) {
                    runLoop = false;
                } else {
                    runLoop = false;
                }
            }
        } catch (error) {
        }

        return cardIds;
    }

    async function getCardId(shop_url: string, lastCharacters: string, additionalInformation: any) {
        const findGiftCard = httpsCallable(functions, "findGiftCard");
        var runLoop = true;
        var response: any;
        var cursor: string = "";
        var counter: number = 0;

        var cardIds: Array<giftCard_idlc> = [];

        while (runLoop && counter < 100) {
            counter += 1;
            try {
                response = await findGiftCard({ shop_url: shop_url, lastCharacters: lastCharacters, additionalInformation: additionalInformation, cursor: cursor });
                if (response.data.extensions.cost.throttleStatus.currentlyAvailable < 102) {
                    await delay(5000);
                }
                if (response.data.querySuccesfull) {
                    cardIds.push(...response.data.giftCards);
                    cursor = response.data.cursor;
                } else if (response.data.noGiftCardsAvailable != undefined && response.data.noGiftCardsAvailable == true) {
                    runLoop = false;
                } else {
                    cursor = response.data.cursor;
                }
            } catch (error) {
                runLoop = false;
            }
        }
        return cardIds;
    }

    async function convertCurrency(from: shopifyMoney, to: shopifyMoney) {
        const convertCurrency = httpsCallable(functions, "convertCurrency");
        var response: any = await convertCurrency({ from: from.currencyCode, to: to.currencyCode, amount: from.amount, useApi: false });
        var toAmount = Math.floor(parseFloat(response.data.result) * 100) / 100;
        var result: convertedShopifyMoney = {
            from: from,
            to: {
                amount: `${toAmount}`,
                currencyCode: to.currencyCode,
            },
            exchange_rate: `${Math.floor((toAmount / parseFloat(from.amount)) * 10000) / 10000}`
        };
        return result;
    }
    
    async function getCustomersByAttributes(shop_url: string, attributes: any) {
        const getCustomersByAttributes = httpsCallable(functions, "getCustomersByAttributes");
        var runLoop = true;
        var response: any;
        var cursor: string = "";
        var counter: number = 0;

        var customers: Array<shopifyCustomer> = [];
        var additionalInformation = "";

        if (attributes != null && attributes.email != null) {
            additionalInformation = `email:${attributes.email}`;
        }

        while (runLoop && counter < 100) {
            counter += 1;
            try {
                response = await getCustomersByAttributes({ shop_url: shop_url, additionalInformation: additionalInformation, cursor: cursor });
       
                if (response.data.querySuccesfull) {
                    customers.push(...response.data.customers);
                    cursor = response.data.cursor;
                } else if (response.data.noCustomersAvailable != undefined && response.data.noCustomersAvailable == true) {
                    runLoop = false;
                } else {
                    cursor = response.data.cursor;
                }
            } catch (error) {
                runLoop = false;
            }
        }
        return customers;
    }

    async function getCardInformation(shop_url: string, cardId: string) {
        const getGiftCardInformation = httpsCallable(functions, "getGiftCardInformation");
        var response: any = await getGiftCardInformation({id: cardId, shop_url: shop_url});
        return response.data.giftCard;
    }

    async function createGiftCard(shop_url: string, expiresOn: string, initialValue: string, note: string = "", customerId: string = "") {
        const createGiftCard = httpsCallable(functions, "createGiftCard");
        
        var response: any = await createGiftCard({
            shop_url: shop_url,
            customerId: customerId,
            expiresOn: expiresOn,
            initialValue: initialValue,
            note: note
        });

        return response;
    }

    async function createCustomer(shop_url: string, email: string, firstName: string, lastName: string = "") {
        const createCustomer = httpsCallable(functions, "createCustomer");
        
        var response: any = await createCustomer({
            shop_url: shop_url,
            email: email,
            firstName: firstName,
            lastName: lastName
        });

        return response;
    }

    async function disableGiftCard(shop_url: string, giftCardId: string) {
        const disableGiftCard = httpsCallable(functions, "disableGiftCard");
        
        var response: any = await disableGiftCard({
            shop_url: shop_url,
            giftCardId: giftCardId
        });

        return response;
    }

    async function storeDataToStorage(documentData: giftCardMigrationHistroy, collection: string) {
        const storeDataToStorage = httpsCallable(functions, "storeDataToStorage");
        
        var response: any = await storeDataToStorage({
            documentData: documentData,
            collection: collection
        });

        return response;
    }

    async function updateGiftCardNote(giftCardId: string, currentGiftCard: giftCard_information, newGiftCard: giftCard_information, newGiftCardCode: string) {
        const updateGiftCard = httpsCallable(functions, "updateGiftCard");

        var today = new Date();
        var newNote = ``;
        if (currentGiftCard.note != "") {
            newNote += 
            `original note: 
${currentGiftCard.note}
----------
`;
        } else {
            newNote += 
            `original note: 
No Note
----------
`;
        }
        newNote +=
`Disabled by GiftCardMigration - ${today.toString()} - 
new shop url: ${newGiftCard.shopUrl} - 
new giftCard code: ${newGiftCardCode}`;
        if (currencyConversion != null) {
            newNote +=
`
original balance: ${currentGiftCard.balance.amount} ${currentGiftCard.balance.currencyCode},
new giftCard balance: ${newGiftCard.balance.amount} ${newGiftCard.balance.currencyCode},
currency exchange rate: ${currencyConversion.exchange_rate}`;
        }
        newNote += 
        `
P&T Mitarbeiter: ${auth.currentUser.email}`;
        
        var response: any = await updateGiftCard({
            shop_url: currentGiftCard.shopUrl,
            id: giftCardId,
            note: newNote
        });

        return response;
    }

    async function runGiftCardMigration() {
        if (selectedGiftCardResult != null) {
            setProcessingMigration(true);
            // Disable Old Gift Card
            var response_1 = await disableGiftCard(selectedGiftCardResult.shopUrl, selectedGiftCardResult.id);
            
            if (response_1?.data?.giftCardDisable?.giftCard?.enabled == false) {
                // Gift Card succesfull disabled
                console.log("Gift Card succesfully disabled");

                // Create new Gift Card
                var expiresOn = "";
                if (newGiftCardExpiresOn != null) {
                    expiresOn = newGiftCardExpiresOn
                }
                var customer = "";
                if (newGiftCardCustomer.customer != null) {
                    customer = newGiftCardCustomer.customer.id;
                }
                var newBalance: shopifyMoney = selectedGiftCardResult.balance;
                var _newGiftCardNote = newGiftCardNote;
                if (currencyConversion != null) {
                    newBalance = currencyConversion.to;
                    _newGiftCardNote +=
`
original balance: ${selectedGiftCardResult.balance.amount} ${selectedGiftCardResult.balance.currencyCode},
new giftCard balance: ${newBalance.amount} ${newBalance.currencyCode},
currency exchange rate: ${currencyConversion.exchange_rate}`;
                }
                console.log(_newGiftCardNote);
                var response_2 = await createGiftCard(
                    newGiftCardTargetShopUrl, 
                    expiresOn, 
                    newBalance.amount,
                    _newGiftCardNote,
                    customer
                );
                if (response_2?.data?.giftCardCreate?.giftCardCode != null) {
                    var date = Date.now();
                    var data = response_2.data.giftCardCreate.giftCard;
                    var giftCardInformation: giftCard_information = {
                        balance: data.balance,
                        customer: data.customer,
                        enabled: data.enabled,
                        expiresOn: data.expiresOn,
                        initialValue: data.initialValue,
                        note: data.note,
                        shopUrl: newGiftCardTargetShopUrl,
                        id: data.id
                    };
                    var giftCardMigrationHistroyData: giftCardMigrationHistroy = {
                        oldGiftCard: selectedGiftCardResult,
                        newGiftCard: giftCardInformation,
                        oldGiftCardLastCharacters: searchedLastCharacters,
                        newGiftCardCode: response_2.data.giftCardCreate.giftCardCode,
                        migrationTimestamp: date.toString(),
                        user: auth.currentUser.email
                    };
                    var response_3 = await storeDataToStorage(giftCardMigrationHistroyData, "migrationHistory");
                    updateGiftCardNote(selectedGiftCardResult.id, selectedGiftCardResult, giftCardInformation, response_2.data.giftCardCreate.giftCardCode)
                        .catch(error => console.error(error));
                    setNewGiftCard({giftCard: giftCardInformation, code: response_2.data.giftCardCreate.giftCardCode });
                } else {
                    console.log("New Gift Card not created");
                    setProcessingMigration(false);
                    setMigrationError("Bestehender Gutschein wurde deaktiviert. Beim Erstellen eines neuen Gutscheins ist ein Fehler aufgetreten.");
                }
            } else {
                console.log("Old Gift Card not disabled");
                setProcessingMigration(false);
                setMigrationError("Bestehender Gutschein konnte nicht deaktiviert werden. Die Migration wurde abgebrochen.");
            }
        }
    }

    // Client Functions

    function setNewGiftCardTargetShopUrl_withCurrency(shopUrl: string) {
        var targetCurrency = getCurrencyCodeForShop(shopUrl);
        if (selectedGiftCardResult != null) {
            var sourceCurrency = selectedGiftCardResult.balance.currencyCode;
            if (targetCurrency != sourceCurrency) {
                setProcessingCurrencyConversion(true);
                convertCurrency(selectedGiftCardResult.balance, { amount: "0", currencyCode: targetCurrency })
                .then(result => {
                    console.log(result);
                    setProcessingCurrencyConversion(false);
                    setCurrencyConversion(result);
                    setNewGiftCardTargetShopUrl(shopUrl);
                })
                .catch(error => {
                    console.log(error);
                    setProcessingCurrencyConversion(false);
                });
            } else {
                setCurrencyConversion(null);
                setNewGiftCardTargetShopUrl(shopUrl);
            }
        }
    }

    function handleKeyPress_searchGiftCard(target: any) {
        if (target.charCode == 13 && searchedLastCharacters != "") {
            searchGiftCard(null);
        }
    }

    function resetGiftCardResults() {
        setGiftCardSearchResults({ amountResults: -1, results: []});
        if (processingMigration != false) {
            setProcessingMigration(false);
        }
        if (newGiftCardTargetShopUrl != "") {
            setNewGiftCardTargetShopUrl("");
        }
        if (newGiftCardNote != "") {
            setNewGiftCardNote("");
        }
        if (newGiftCardExpiresOn != "") {
            setNewGiftCardExpiresOn("");
        }
        if (newGiftCard != null) {
            setNewGiftCard(null);
        }
        if (selectedGiftCardResult != null) {
            setSelectedGiftCardResult(null);
        }
        if (currencyConversion != null) {
            setCurrencyConversion(null);
        }
        if (processingCurrencyConversion != false) {
            setProcessingCurrencyConversion(false);
        }
    }

    var migrateGiftCard = (event: any) => {
        runGiftCardMigration()
        .then(response => console.log(response));
    }

    var continueWithoutCustomer = (event: any) => {
        setNewGiftCardCustomer({unset: false, customer: null});
    }

    var searchCustomer = (event: any) => {
        if (newGiftCardTargetShopUrl != "" && searchGiftCardCustomerMail != "") {
            getCustomersByAttributes(newGiftCardTargetShopUrl, { email: searchGiftCardCustomerMail })
            .then(response => {
                setNewGiftCardCustomerSearchResults({ amountResults: response.length, results: response });
            });
        } else {
            console.error("shop url oder email nicht gesetzt", newGiftCardTargetShopUrl, searchGiftCardCustomerMail);
        }
    }

    var searchGiftCard = (event: any) => {
        var validation = validateLastCharacters(searchedLastCharacters);
        if (validation.error == 0) {
            resetGiftCardResults();
            setProcessingSearch(true);
            searchGiftCardInAllShops(searchedLastCharacters, "status:enabled")
            .catch(error => console.error(error));
        }
    };

    function validateLastCharacters(lastCharacters: string) {
        if (lastCharacters.length > 4) {
            return {
                error: 1,
                message: "Du hast mehr als 4 Zeichen eingegeben."
            }
        } else if (lastCharacters.length < 4) {
            return {
                error: 1,
                message: "Du hast weniger als 4 Zeichen eingegeben."
            }
        } else {
            return {
                error: 0,
                message: ""
            }
        }
    }

    function selectGiftCardResult(index: number) {
        var today = new Date();
        var newGiftCardNote = ``;
        if (giftCardSearchResults.results[index].note != "") {
            newGiftCardNote += 
            `original note:
${giftCardSearchResults.results[index].note}
----------
`;
        } else {
            newGiftCardNote += 
            `no original note
----------
`;
        }
        newGiftCardNote += `Created by GiftCardMigration - ${today.toString()} - 
original lastCharacters: ${searchedLastCharacters} - 
original initalValue: ${giftCardSearchResults.results[index].initialValue.amount} ${giftCardSearchResults.results[index].initialValue.currencyCode} - 
original shop url: ${giftCardSearchResults.results[index].shopUrl} - 
original customer: `;
        var customer = giftCardSearchResults.results[index].customer;
        if (customer != null) {
            newGiftCardNote += `${customer.displayName} ${customer.email} - `;
        } else {
            newGiftCardNote += `No Customer - `;
        }
        newGiftCardNote += 
        `
P&T Mitarbeiter: ${auth.currentUser.email}`;

        setNewGiftCardNote(newGiftCardNote);
        setNewGiftCardExpiresOn(giftCardSearchResults.results[index].expiresOn);
        setSelectedGiftCardResult(giftCardSearchResults.results[index]);
    }

    // Render functions

    var renderGiftCards = giftCardSearchResults.results.map((item, index) => {
        return (
            <Card className="mt-3 bg-light">
                {/* <Card.Header>Gutschein{index > -1 && ` ${index + 1}`}</Card.Header> */}
                <Card.Body className="text-center" >
                    { renderGiftCardAttributes(item, processingMigration, processingSearch, index) }
                    {item.enabled && 
                        <Button className="mt-3 text-end text-center" variant="primary" onClick={(event) => selectGiftCardResult(index)}>
                            Auswählen
                        </Button>
                    }
                </Card.Body>
            </Card>
        );
    });

    const renderSelectedGiftCard = () => {
        if (selectedGiftCardResult != null) {
            return renderGiftCardAttributes(selectedGiftCardResult, processingMigration, processingSearch);
        } else {
            return (<p>Kein Gutschein gefunden</p>);
        }
    }

    return (
        <Container className="px-3 py-4 formularWrapper">
            <h2>Gift Card Migration</h2>
            <Container className="mt-3 p-0">
                <Card body>
                    <Form.Group className="mb-3" controlId="giftCardCode">
                        <Form.Label>Gutscheincode</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="z.B. 5d7e"
                            className="text-uppercase giftCardCodeSearchInput"
                            maxLength={4}
                            onKeyPress={handleKeyPress_searchGiftCard}
                            onChange={e => setSearchedLastCharacters(e.target.value.toLowerCase())}
                        />
                        <Form.Text className="text-muted">
                        Gib die letzten 4 Zeichen des Gutscheincodes ein.
                        </Form.Text>
                    </Form.Group>

                    <Button 
                        variant="primary" 
                        type="submit" 
                        onClick={searchGiftCard}
                        disabled={processingSearch || processingMigration || processingCurrencyConversion}
                    >
                        {processingSearch == true ? 
                        <>
                            <Spinner animation="border" role="status" size="sm" aria-hidden="true"/>
                            <span className="visually-hidden">Loading...</span>
                        </>
                        : 
                        <>
                            Gutschein suchen
                        </>
                        }
                    </Button>
                </Card> 
            </Container>
            { processingSearch &&
                <Container className="mt-3 p-0">
                    <Alert variant="info">
                    Gutscheine werden gesucht...
                    </Alert>
                </Container>
            }
            { giftCardSearchResults.amountResults > 0 && selectedGiftCardResult == null &&
                <Container className="mt-4 p-0">
                    <>
                        <h3>Suchergebnisse</h3>
                        { renderGiftCards }
                    </>
                </Container>
            }
            { giftCardSearchResults.amountResults == 0 && selectedGiftCardResult == null &&
                <Container className="mt-3 p-0">
                    <Alert variant="danger">
                        Es konnten keine Gutscheine zu den eingegebenen Zeichen gefunden werden.
                    </Alert>
                </Container>
            }
            { selectedGiftCardResult != null &&
                <Container className="mt-4 p-0">
                    <>
                        <h3>Gutschein migrieren</h3>
                        <Card body className="mt-2">
                            <h5>Bestehender Gutschein</h5>
                            <Card className="mt-2 bg-light">
                                <Card.Body>
                                    { renderSelectedGiftCard() }
                                </Card.Body>
                            </Card>
                            { newGiftCard == null &&
                                <>
                                    {/* <h4 className="mt-4">Konfiguration neuer Gutschein</h4> */}
                                    <h5 className="mt-4">Ziel-Shop auswählen</h5>
                                    <Form>
                                        <Row>
                                            <Col key="col_1">
                                                <Form.Group className="mb-3" controlId="sourceShop">
                                                    <Form.Label>Quell-Shop</Form.Label>
                                                    <Form.Control 
                                                        type="text" 
                                                        value={ shopLabels[selectedGiftCardResult.shopUrl] }
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col key="col_2">
                                                <Form.Group className="mb-3" controlId="targetShop">
                                                    <Form.Label aria-label="Hinweis">Ziel-Shop</Form.Label>
                                                    <Form.Select 
                                                        aria-label="Default select example" 
                                                        id="targetShopSelect"
                                                        value={newGiftCardTargetShopUrl}
                                                        onChange={e => setNewGiftCardTargetShopUrl_withCurrency(e.target.value)}
                                                    >
                                                        <option disabled={true} value="">Shop auswählen</option>
                                                        {shops.map(item => {
                                                            return <option disabled={item.url == selectedGiftCardResult.shopUrl} value={item.url}>{item.label}</option>
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        { processingCurrencyConversion &&
                                            <Row className="mb-3">
                                                <Col key="col_3" className="text-center">
                                                    <Spinner animation="border" variant="secondary" size="sm" aria-hidden="true"/>
                                                    <span className="visually-hidden">Loading...</span>
                                                </Col>
                                            </Row>
                                        }
                                        { currencyConversion != null &&
                                            <Row>
                                                <Col key="col_4">
                                                    <Alert className="mb-2" variant="info">
                                                        <p>Der Ziel-Shop hat eine andere Währung</p>
                                                        <p className="mb-0">Wechselkurs: { currencyConversion.exchange_rate }</p>
                                                        <p className="mb-0">Zielbetrag: <b>{ currencyConversion.to.amount }</b> { currencyConversion.to.currencyCode }</p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        }
                                    </Form>
                                    { newGiftCardTargetShopUrl != "" &&
                                        <>
                                            {/* <h5 className="mt-2">2. Kunde auswählen</h5>
                                            <Form className="mt-2">
                                                {newGiftCardCustomer.unset == false && newGiftCardCustomer.customer == null ? 
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <p>Neuer Gutschein wird ohne Kunde erstellt.</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="customerEmail">
                                                                <Button variant="secondary" onClick={continueWithoutCustomer}>
                                                                    Kunde für Gutschein setzen
                                                                </Button>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <>
                                                    <Row key="selectCustomerRow_1">
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="customerEmail">
                                                                <Form.Label>Vorname</Form.Label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    placeholder="z.B. Max"
                                                                    value={selectedGiftCardResult.customer != null && selectedGiftCardResult.customer.firstName != null ? selectedGiftCardResult.customer.firstName : ""}
                                                                    onChange={e => console.log(e.target.value)}
                                                                    />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="customerEmail">
                                                                <Form.Label>Nachname</Form.Label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    placeholder="z.B. Mustermann"
                                                                    value={selectedGiftCardResult.customer != null && selectedGiftCardResult.customer.lastName != null ? selectedGiftCardResult.customer.lastName : ""}
                                                                    onChange={e => console.log(e.target.value)}
                                                                    />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row key="selectCustomerRow_2">
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="customerEmail">
                                                                <Form.Label>E-Mail</Form.Label>
                                                                <Form.Control 
                                                                    type="email" 
                                                                    placeholder="z.B. name@server.de"
                                                                    value={selectedGiftCardResult.customer != null && selectedGiftCardResult.customer.email != null ? selectedGiftCardResult.customer.email : ""}
                                                                    onChange={e => setSearchedGiftCardCustomerMail(e.target.value)}
                                                                    />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row  key="selectCustomerRow_3">
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="customerEmail">
                                                                <Button className="me-2" variant="primary" onClick={searchCustomer}>
                                                                    Kunde suchen
                                                                </Button>
                                                                <Button variant="secondary" onClick={continueWithoutCustomer}>
                                                                    Ohne Kunde fortfahren
                                                                </Button>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </>
                                                }
                                            </Form>
                                            {newGiftCardCustomer.unset == false && 
                                                <> */}
                                                    {/* <h5 className="mt-2">2. Gutschein konfigurieren</h5> */}
                                                    <Form className="mt-2">
    {/*                                                     
                                                        <Row>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="giftCardCode">
                                                                    <Form.Label>Ablaufdatum (optional)</Form.Label>
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        value={newGiftCardExpiresOn == null ? "" : newGiftCardExpiresOn}
                                                                        placeholder="z.B. 2022-10-25"
                                                                        onChange={e => setNewGiftCardExpiresOn(e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row> */}
                                                        <Row>
                                                            <Col>
                                                                <Button 
                                                                    variant="primary" 
                                                                    type="submit" 
                                                                    onClick={migrateGiftCard}
                                                                    disabled={processingMigration || processingCurrencyConversion}
                                                                >
                                                                    {processingMigration == true ? 
                                                                    <>
                                                                        <Spinner animation="border" role="status" size="sm" aria-hidden="true"/>
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        Gutschein migrieren
                                                                    </>
                                                                    }
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        {migrationError != "" &&
                                                            <Alert className="mt-3" variant="danger">{migrationError}</Alert>
                                                        }
                                                    </Form>
                                                {/* </>
                                            }*/}
                                        </> 
                                    }
                                    {/* <Card className="mt-1">
                                        <Card.Header>Neuer Gutschein</Card.Header>
                                        <Card.Body>
                                            { renderNewGiftCard() }
                                        </Card.Body>
                                    </Card>
                                    <Button className="mt-3" variant="primary" type="submit" onClick={searchGiftCard}>
                                        Gutschein migrieren
                                    </Button> */}
                                </>
                            }
                            { newGiftCard != null &&
                                <>
                                    { currencyConversion != null &&
                                        <Alert className="mt-3" variant="info">
                                            <p>Der Ziel-Shop hat eine andere Währung</p>
                                            <p className="mb-0">Wechselkurs: { currencyConversion.exchange_rate }</p>
                                            <p className="mb-0">Zielbetrag: <b>{ currencyConversion.to.amount }</b> { currencyConversion.to.currencyCode }</p>
                                        </Alert>
                                    }
                                    <Alert className="mt-3" variant="success">
                                        Bestehender Gutschein wurde erfolgreich migriert.
                                    </Alert>
                                    <h5 className="mt-3">Neuer Gutschein</h5>
                                    <Card className="mt-2 bg-light">
                                        <Card.Body>
                                            { renderGiftCardAttributes(newGiftCard.giftCard, processingMigration, processingSearch, -1, newGiftCard.code) }
                                        </Card.Body>
                                    </Card>
                                </>
                            }
                        </Card>
                    </>
                </Container>
            }

        </Container>

    );

}