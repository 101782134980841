import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { giftCard_information, shopLabels_type, shopItem, giftCardMigrationHistroy } from "../interfaces";
import Button from "react-bootstrap/Button";
import Placeholder from "react-bootstrap/Placeholder";

export const shops: Array<shopItem> = [
    {
        url: "paperandtea",
        label: "Deutschland"
    },
    {
        url: "paperandtea-at",
        label: "Österreich"
    },
    {
        url: "paperandtea-en",
        label: "International"
    },
    // {
    //     url: "paperandtea-at-dev",
    //     label: "AT DEV"
    // },
    // {
    //     url: "paperandtea-de-dev",
    //     label: "DE DEV"
    // },
    {
        url: "paperandtea-ch",
        label: "Schweiz"
    },
    {
        url: "paperandtea-dk",
        label: "Dänemark"
    },
    {
        url: "paperandtea-no",
        label: "Norwegen"
    }
];

export const shopLabels: shopLabels_type = {
    "paperandtea-de-dev": "Deutschland DEV",
    "paperandtea-at-dev": "Österreich DEV",
    "paperandtea": "Deutschland",
    "paperandtea-at": "Österreich",
    "paperandtea-en": "International",
    "paperandtea-ch": "Schweiz",
    "paperandtea-dk": "Dänemark",
    "paperandtea-no": "Norwegen"
}

export function getCurrencyCodeForShop(shopUrl: string) {
    switch (shopUrl) {
        case "paperandtea":
            return "EUR";
        case "paperandtea-en":
            return "EUR";
        case "paperandtea-at":
            return "EUR";
        case "paperandtea-at-dev":
            return "EUR";
        case "paperandtea-de-dev":
            return "EUR";
        case "paperandtea-ch":
            return "CHF";
        case "paperandtea-dk":
            return "DKK";
        case "paperandtea-no":
            return "NOK";
        default:
            return "EUR";
    }
}

function formatCode (code: string): Array<string> {
    const result = [code.slice(0, 4).toUpperCase(), code.slice(4,8).toUpperCase(), code.slice(8,12).toUpperCase(), code.slice(12,16).toUpperCase()];
    return result;
}

function copyGiftCardCode(code: string) {
    navigator.clipboard.writeText(code);
}

export function renderCustomerAttributes (attributes: Array<{label: string, value: string}>) {
    // console.log(attributes);
    return (
        attributes.map((attribute: {label: string, value: string}, index: number) => {
            return (<Row className="mb-1" key={`row_${index}`}>
                <Col key="col_1">
                    <p><small>{attribute.label}</small></p>
                    { attribute.value == "null" ?
                        <Placeholder as="p" animation="wave">
                            <Placeholder xs={1} />
                        </Placeholder>
                    :
                        <p><b>{attribute.value}</b></p>
                    }
                </Col>
            </Row>)
        })
    )
}

export function renderGiftCardAttributes (item: giftCard_information, processingMigration: boolean, processingSearch: boolean, index: number = -1, code: string = "", lastCharacters: string = "") {
    var formattedCode: Array<string> = [];
    if (code != "") {
        formattedCode = formatCode(code);
    }
    return (
        <Container className="text-center p-0">
            {code != "" &&
                <Container className="p-0 mb-2 text-uppercase">
                    <big><b>
                        <span className="me-1">{ formattedCode[0] }</span>
                        <span className="me-1">{ formattedCode[1] }</span>
                        <span className="me-1">{ formattedCode[2] }</span>
                        <span>{ formattedCode[3] }</span>
                    </b></big>
                </Container>
            }
            {lastCharacters != "" &&
                <Container className="p-0 mb-2 text-uppercase">
                    <big><b>
                        <span className="me-1">????</span>
                        <span className="me-1">????</span>
                        <span className="me-1">????</span>
                        <span>{ lastCharacters.toUpperCase() }</span>
                    </b></big>
                </Container>
            }
            <Container className="p-0">
                {item.customer == null && "Kein Kunde"}
                {item.customer != null && item.customer.displayName && `${item.customer.displayName}` }
                {item.customer != null && item.customer.email && <br/> }
                {item.customer != null && item.customer.email && `${item.customer.email}` }
            </Container>
            <Container className="p-0 my-3">
                <big><b>{item.balance.amount}</b> {item.balance.currencyCode} von <b>{item.initialValue.amount}</b> {item.balance.currencyCode}</big>
            </Container>
            <Container className="p-0">
                { shopLabels[item.shopUrl] }
            </Container>
            <Container className="mt-2 p-0">
                <small>
                    { item.expiresOn == null ?
                        "Kein Ablaufdatum"
                        :
                        `Ablaufdatum: ${item.expiresOn}`
                    }
                </small>
            </Container>
            {code != "" && 
                <Container className="p-0 mt-2">
                    <Button 
                        variant="primary" 
                        onClick={() => copyGiftCardCode(code)}
                        disabled={processingSearch || processingMigration}
                    >Code kopieren</Button>
                </Container>
            }
        </Container>
    );
};

function appendTrailingZero(number: number) {
    if (number < 10 && number > 0) {
        return `0${number}`;
    } else {
        return `${number}`;
    }
}

function getDayForNumber(dayNumber: number) {
    switch (dayNumber) {
        case 1:
            return "Montag";
        case 2:
            return "Dienstag";
        case 3:
            return "Mittwoch";
        case 4:
            return "Donnerstag";
        case 5:
            return "Freitag";
        case 6:
            return "Samstag";
        case 7:
            return "Sonntag";
    }
}

function getMonthForNumber(monthNumber: number) {
    switch (monthNumber) {
        case 1:
            return "Jan";
        case 2:
            return "Feb";
        case 3:
            return "Mrz";
        case 4:
            return "Apr";
        case 5:
            return "Mai";
        case 6:
            return "Jun";
        case 7:
            return "Jul";
        case 8:
            return "Aug";
        case 9:
            return "Sep";
        case 10:
            return "Okt";
        case 11:
            return "Nov";
        case 12:
            return "Dez";
    }
}

export function getTimeStringFromUnix(unixTime: number) {
    var date = new Date(unixTime);
    var dateString = `${getDayForNumber(date.getDay())}, ${appendTrailingZero(date.getDate())}. ${getMonthForNumber(date.getMonth() + 1)} ${date.getFullYear()} - ${appendTrailingZero(date.getHours())}:${appendTrailingZero(date.getMinutes())}`;
    return dateString;
}