import React, { useContext, useState, useEffect } from "react";
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, User } from "firebase/auth";
import { auth } from "../firebase";
import { FirebaseApp } from "firebase/app";

const AuthContext: React.Context<any> = React.createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: any) {


  const [currentUser, setCurrentUser] = useState<User | null>(null);
  
  const [loading, setLoading] = useState<boolean>(true);

  // function signup(email: string, password: string) {
  //   return createUserWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
  //       const user = userCredential.user;
  //     })
  //     .catch((error) => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //     });
  // }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      setCurrentUser(userCredential.user);
    });
  }

  function logout() {
    return auth.signOut();
  }

  // function resetPassword(email: string) {
  //   return auth.sendPasswordResetEmail(email);
  // }

  // function updateEmail(email: string) {
  //   return currentUser?.updateEmail(email);
  // }

  // function updatePassword(password: string) {
  //   return currentUser?.updatePassword(password);
  // }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log("User Logged in:", user);
        // ...
        if (currentUser != user) {
          setCurrentUser(user);
        }
        setLoading(false);
      } else {
        if (currentUser != user) {
          setCurrentUser(user);
        }
        console.log("User NOT Logged in", user);
        // User is signed out
        // ...
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        // signup,
        login,
        logout,
        // resetPassword,
        // updateEmail,
        // updatePassword,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
}
