// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAYsQ7rpZnaG5nF1PjVXyFJ5L3iGwhJw7o",
  authDomain: "shopify-giftcard-migration.firebaseapp.com",
  projectId: "shopify-giftcard-migration",
  storageBucket: "shopify-giftcard-migration.appspot.com",
  messagingSenderId: "957519301124",
  appId: "1:957519301124:web:0a08da98b4bcf064400351"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const database = getFirestore(app);
// Init FUnctions
export const functions = getFunctions(app);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;