import './App.css';
import React from 'react';
import { Route, Routes } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./Provider/AuthProvider";
import Login from "./Authentication/Login";
import PrivateRoute from "./Authentication/PrivateRoute";
import Dashboard from "./Dashboard/Dashboard";
import GiftCardMigration from "./GiftCardMigration/GiftCardMigration";
import GiftCardMigrationHistory from "./GiftCardMigrationHistory/GiftCardMigrationHistory";
import LoyaltyPointsMigration from "./LoyaltyPointsMigration/LoyaltyPointsMigration";
import PT_NavBar from "./PT_NavBar/PT_NavBar";

function App() {
  return(
    <Router>
      <AuthProvider>
        <PT_NavBar />
        <Routes>
          <Route path="/" element={
            <PrivateRoute>
              <GiftCardMigration />
            </PrivateRoute>
            } 
          />
          <Route path="/giftCardMigration" element={
            <PrivateRoute>
              <GiftCardMigration />
            </PrivateRoute>
            } 
          />
          <Route path="/giftCardMigration/history" element={
            <PrivateRoute>
              <GiftCardMigrationHistory />
            </PrivateRoute>
            } 
          />
          <Route path="/loyaltyPointsMigration" element={
            <PrivateRoute>
              <LoyaltyPointsMigration />
            </PrivateRoute>
            } 
          />
          {/* <Route path="/test" element={
            <PrivateRoute>
              <Test />
            </PrivateRoute>
            } 
          /> */}
          <Route path="/login" element={<Login />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
